<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="12" lg="12">
        <b-card class="w-100" no-header>
          <template slot="header">
            <span class="mt-2">{{model.name}}</span>
            <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
          </template>

          <b-card no-body>
            <b-card-header>

              {{model.name}}
              
            </b-card-header>

            <b-card-body>

              <p><b>Preview</b></p>

              <div class="w-100" v-html="model.html">

              </div>

              <hr>

              <p v-if="!model.protected" class="mt-3"><b>Edit</b></p>

              <b-form-row v-if="!model.protected" class="mb-3">

                <b-col cols="12">
                  <editor v-model="model.html" :options="editorOption" :toolbar="toolbarOptions"></editor>
                </b-col>
              </b-form-row>

            </b-card-body>
            <b-card-footer v-if="!model.protected" class="bg-transparent">
              <b-button
                @click="update"
                variant="outline-success"
                class="float-right"
                >Submit</b-button
              >
            </b-card-footer>
          </b-card>

          <template slot="footer">
            <b-button @click="goBack" class="pull-left">Back</b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "../../../shared/axios/auth";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueQuillEditor from 'vue-quill-editor'

  // require styles
  import 'quill/dist/quill.snow.css'

  var toolbarOptions = [
    ["bold","italic","underline",],
    [{list:"ordered"},{list:"bullet"}],
    [{header:1},{header:2},{header:3}],
    [{header:[1,2,3,4,5,6,!1]}]
  ]

export default {
  name: "PortalPage",
  mixin: [validationMixin],
  components: {
      editor: VueQuillEditor.quillEditor
  },
  data: () => {
    return {
        toolbarOptions: toolbarOptions,
        editorOption: {
          theme: 'snow',
          modules: {
            toolbar: toolbarOptions
          },
        },
      model: null,
    };
  },
  validations() {
    return {
      model: {
        period: {
          required
        }
      }
    };
  },
  methods: {
    validateState(name) {
      if (this.$v.model[name]) {
        const { $dirty, $error } = this.$v.model[name];
        return $dirty ? !$error : null;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    get() {
      let url = "/spark/elements/html/" + this.$route.params.id + "/";

      axios
        .get(url)
        .then(response => {
          this.model = response.data;
        })
        .catch(error => {
          // Unauthorised
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
          }
        });

      return this.quote;
    },
    update() {
      this.error = false;

      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      } else {
        // Valid

        let url = "/spark/elements/html/" + this.$route.params.id + "/";

        var post_data = {
          type: this.model.type,
          period: this.model.period
        };

        axios
          .put(url, post_data)
          .then(response => {
            console.log(response.data);
            this.error = false;
            //this.$router.push({ name: "QuestionSections" });
          })
          .catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch("logout");
            }
          });
      }
    }
  },
  mounted() {
    this.get();
  }
};
</script>

<style>
#data .card-header {
  padding-top: 1.5rem;
}
</style>
